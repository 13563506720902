.MuiAlert-root {
    .MuiAlert-message {
        color: #000028 !important;
    }
}

.adminMenu-item-container {
    position: relative;
    display: flex;
    background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;

    .adminMenu-item-text-block {
        padding: 20px;
    }
}
