$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;
$largeScreen: 1980px;
.leap-lr--contact {
  margin: 40px 40px 0px 40px;
  border-width: 0px;
  border-left: 10px solid;
  border-image: linear-gradient(
      to bottom,
      var(--light-green),
      var(--light-green--2)
    )
    1 100%;
  max-width: 450px;
  /*transform: translate(-50%, 75%);
    -ms-transform: translate(-50%, 75%);
    -webkit-transform: translate(-50%, 75%);
    position: absolute;
    top: 0%;
    left: 20%;*/
  padding: 20px;
}

.contact-cognisphere {
    position: fixed;
    top: -40vh;
    right: -70vw;

    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
        display: block;
        position: fixed;
        left: -80vw;
        top: -10vh;
    }

    @media screen and (min-width: $mediumTablet) and (max-width: $desktop) {
        display: block;
        position: fixed;
        right: -90vw;
        bottom: -22vh;
    }

    @media screen and (min-width: $desktop) and (max-width: $largeScreen) {
        display: block;
        position: fixed;
        right: -45vw;
        bottom: -22vh;
    }
}

.ml-70px {
  margin-left: 70px;
}
.d-block {
  display: block !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.contact-mail-desc {
  color: var(--petrol-green);
  border-bottom: 1px solid var(--petrol-green);
}
