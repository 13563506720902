.MuiAlert-root {
    .MuiAlert-message {
        color: #000028 !important;
    }
}

.adminMenu-item-container {
    position: relative;
    display: flex;
    background: var(--landing-page-card-bg-color);
    box-shadow: 0 0 10px var(--light-sand);
    border-radius: 8px;

    .adminMenu-item-text-block {
        padding: 20px;
    }
}

#DownloadDataBtn {
    margin-bottom: 1em !important;
}

.MuiDialog-paperWidthMd {
    width: 100%;
}

.MuiOutlinedInput-root {
    position: relative;
    font-size: 14px;
    transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    background-color: var(--input-bg-color);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.mng-button {
    margin-left: 1em !important;
    margin-bottom: 1em !important;
    padding-top: 1em !important;
}