$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.homepage-cognisphere {
    position: fixed;
    top: -40vh;
    right: -70vw;

    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
        display: none;
    }

    @media screen and (min-width: $mediumTablet) and (max-width: $desktop) {
        display: block;
        position: fixed;
        right: -140vw;
        bottom: -22vh;
    }
}

.bg-landing-page {
    //background-image: url("../../assets/images/Cognisphere_Transparent.png");
    .homepage-container {
        // align-items: center;
        position: relative;

        .video-tag {
            position: fixed;
            left: 0;
            top: 0;
            width: 30%;
            height: 100%;
        }

        .logged-in-user {
            padding-top: 200px;
            padding-right: 80px;
            color: var(--dark-blue);

            @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
                padding-right: 0px;
                padding-top: 0px;
            }

            .logged-in-user-heading {
                margin-bottom: 0;
                font-size: 32px;
                border-left: 8px solid var(--light-green);

                @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
                    font-size: 32px;
                    padding-right: 0px;
                    text-align: left;
                }

                @media screen and (min-width: $mediumTablet) and (max-width: $largeTablet) {
                    font-size: 32px;
                    padding-right: 60px;
                }

                .logged-in-greetings {
                    font-family: "SiemensSans-Bold", sans-serif;
                    padding: 0 10px;
                }

                .logged-in-user-name {
                    font-family: "SiemensSans-Black", sans-serif;
                    text-transform: uppercase;
                }
            }

            .logged-in-project-desc {
                font-size: 24px;
                margin-bottom: 0;
            }
        }

        .landing-page-menu {
            margin: 40px 0;

            .menu-item-container {
                position: relative;
                display: flex;
                align-items: center;
                background: var(--landing-page-card-bg-color);
                box-shadow: 0 0 10px var(--light-sand);
                border-radius: 8px;

                .menu-item-image-block {
                    background: var(--white);
                    margin: 10px;
                    position: relative;
                    left: -30px;
                    border-radius: 10px;
                    border: 1px solid var(--light-green);

                    .menu-item-image {
                        height: 80px;
                        width: 80px;
                        left: 40px;
                    }
                }

                .menu-item-text-block {
                    display: flex;
                    padding: 20px;

                    span {
                        position: relative;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .resume-watch-image {
        height: 200px;
        width: 100%;
        box-shadow: 5px 2px 10px 5px #ccc;
    }

    .course-info {
        padding-left: 2rem;
        padding-bottom: 10px;
    }

    .p-1 {
        padding: 1em;
    }

    .resume-block {
        margin-left: 2em;
        box-shadow: 3px 3px 3px 2px #ccc;
    }

    .details-block {
        box-shadow: 3px 3px 3px #ccc;
    }

    .extras-block {
        margin-left: 2em;
        box-shadow: 0px 4px 6px 2px #ccc;
        background: #e8f8f8;

        @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
            margin-left: 0em !important;
        }
    }

    .p-10px {
        padding: 10px;
    }

    .border-dotted {
        border-bottom: 2px dotted var(--blue-green);
    }

    .bg-light {
        background: #E8F8F8;
    }
}

    #ResumeartCourseBtn {
        border-radius: 10px;
    }

    .category-select {
        margin-right: 5em !important;

        @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
            margin-right: 1em !important;
        }
    }

    .bd-bottom {
        border-bottom: 1px solid var(--petrol-green);
    }

    .overview-title {
        color: var(--blue-green);
        margin: 0px;
        border-bottom: 2px solid var(--petrol-green)
    }

.clr-green{
    color:#00cccc !important;
}

.align-center{
align-self:center;
}