$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.bg-login-register {
  background: url("../../assets/images/leap.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  min-height: 111vh;
}

.leap-lr--project-block {
  display: grid;
  justify-content: flex-start;
  align-items: center;
}

/*Left section of Login Register Screen*/
.leap-lr--project {
  background: #f3f3f0cc;
  margin: 20px;
  border-width: 0px;
  border-left: 10px solid;
  border-image: linear-gradient(
      to bottom,
      var(--light-green),
      var(--light-green--2)
    )
    1 100%;
  min-width: 365px;
  /*transform: translate(-50%, 75%);
    -ms-transform: translate(-50%, 75%);
    -webkit-transform: translate(-50%, 75%);
    position: absolute;
    top: 0%;
    left: 20%;*/
  padding: 20px;
}

.leap-lr-project-name {
  font-family: "SiemensSans-Black";
  font-size: 1.5rem;
  color: var(--dark-blue);
  margin: 0;
  /*background: -webkit-linear-gradient(90deg, var(--master-blue), var(--blue-green), var(--master-blue), var(--blue-green), var(--master-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
}

.leap-lr--project-description {
  font-size: 1.125rem;
  display: block;
  margin: 15px 0;
}

/*Right Section of Login Register Screen*/
.leap-lr-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}

.leap-lr-logo {
  text-align: center;
  padding: 40px;
}

.leap-intra-logo {
  height: 1rem;
  margin: 0 10px;
}

.official-logo {
  height: 1.4rem;
  margin-top: 5px;
}

.tabs-additional-padding {
  padding: 0 100px;
}

.leap-button--login,
.leap-button--register {
  width: 50%;
  transition: 0.5s;
}

.leap-button--login-icon,
.leap-button--register-icon {
  font-size: 0.75rem !important;
  border-radius: 50%;
  background: transparent !important;
}

/*Mobile*/
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .official-logo {
    margin-right: 20px;
    padding-top: 5px;
  }

  .leap-button--login,
  .leap-button--register {
    width: 60%;
  }

  .tabs-additional-padding {
    padding: 0;
  }
}

/*Mid-mobile*/
@media only screen and (min-width: 600px) and (max-width: 767px) {
  /*.leap-lr-mobileContainer {
        display: flex;
        flex-direction: column-reverse;
    }*/

  .tabs-additional-padding {
    padding: 0px;
  }
}

/*Small Tablet*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .official-logo {
    height: 1.1rem;
  }

  /*.leap-lr-mobileContainer {
        display: flex;
        flex-direction: column-reverse;
    }*/

  .leap-lr--project-description {
    top: -10%;
    left: 25%;
  }

  .tabs-additional-padding {
    padding: 0px;
  }
}

/*Large Tablet*/
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .leap-lr--project-description {
    top: -5%;
    left: 25%;
  }
}
.pt-lr-block {
    padding: 16px 40px;
    background-color: rgb(255,255,255, 0.8);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.align-self-center {
  align-self: center;
}

.leap-lr-mobileContainer {
    padding: 10px 10px;
    justify-content: space-between;
    //flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
        padding: 0px 20px;
    }
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.m-0 {
    margin: 0;
}
.myid-login-btn-block {
    margin: 10px 0 0;
}
.supplier-login-btn-block {
    margin: 10px 0 0;
}
.pt-divider {
    margin: 10px;
}
.dashed {
    border-width:2px;
}